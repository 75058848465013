@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  /* font-family: 'DM Sans', sans-serif; */
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6, p {
  margin-block-start: 0.1em;
  margin-block-end: 0.1em;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: #de5b28;
}

.MuiButton-root {
  border-radius: 0 !important;
  width: fit-content;
  padding: 5px 20px !important;
  text-transform: unset !important;
  display: block !important;
  font-family: 'Source Sans Pro', sans-serif !important;
}

.MuiTypography-root {
  font-family: 'DM Sans', sans-serif !important;
}

.MuiLoadingButton-loadingIndicator {
  color: #ffffff !important;
  top: 13px;
}

.swal2-container {
  z-index: 9999 !important;
}

* {
  box-sizing: border-box;
}

/* .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: auto;
  max-width: unset !important;
} */
